import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getProfileThunk, loginSelector } from "../../../login/slice";
import {
  buildFileReportThunk,
  getReportThunk,
  listReportFilesThunk,
  selectReportPageState,
} from "./slice";
import StateStatus from "../../../../utils/stateStatus";
import { Button, Col, Modal, Row, Select, message } from "antd";
import { beautifyCpfCnpj } from "../../../../utils/formatters";
import Icons from "../../../../common/components/Icons";
import { TargetPage } from "../target-page";
import { HeaderDash } from "../../../../common/components/header";
import { TargetType } from "../list_reports/interfaces";
import { LoadingSkeleton } from "../../../../common/components/loading";
import { DocPage } from "../doc-page";
import { StatusIcon } from "../../../../utils/status";
import { BlueSquareButton } from "../../../../common/components/button";
import { ReactComponent as VideoSvg } from "../../../../common/images/video.svg";
import { ReactComponent as GraphSvg } from "../../../../common/images/graph.svg";
import "./styles.scss";


const ReportPage = () => {
  const state = useSelector(selectReportPageState);
  const loginState = useSelector(loginSelector);
  const [target, setTarget] = useState<TargetType>();
  const [showParecerModal, setShowParecerModal] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profile = loginState.data?.profile;

  useEffect(() => {
    if (state.status.getReport !== StateStatus.loading) {
      dispatch(getProfileThunk());
      dispatch(getReportThunk(params.reportId!));
    }
  }, [params.reportId]);

  useEffect(() => {
    changeTargetId(params.targetId);
  }, []);

  useEffect(() => {
    changeTargetId(params.targetId);
  }, [params.targetId, state.data.report?.id]);

  useEffect(() => {
    if (state.status.getReport === StateStatus.succeeded) {
      dispatch(listReportFilesThunk(params.reportId!));
    }
  }, [state.status.getReport]);

  useEffect(() => {
    if (state.status.listReportFiles === StateStatus.succeeded) {
      if (
        !state.data.files?.has_file_requested &&
        ["completed", "released"].includes(state.data.report?.status ?? "")
      ) {
        // Request files to be created
        dispatch(buildFileReportThunk(params.reportId!));
      }
    }
  }, [state.status.listReportFiles]);

  useEffect(() => {
    if (loginState.status.getProfile === StateStatus.succeeded) {
      //
    }
  }, [params.reportId]);

  const copyPasswordVideo = () => {
    if(state.data?.report?.video_description_password) {
      navigator.clipboard.writeText(state.data?.report?.video_description_password);
      message.success("Senha copiada para área de trasnferência");
    }
  };

  const buildLink = (tabKey) => {
    return `${location.pathname}?tab=${tabKey}`
  }

  const changeTargetId = (targetId) => {
    if (!targetId) return null;

    if (!target?.id || target?.id !== targetId) {
      const targetValue = state.data.report?.targets?.filter(
        (x) => x.id === targetId
      )[0];
      if (targetValue) setTarget(targetValue);
    }
  };

  return (
    <>
      <HeaderDash />
      <div className="report-view-page">
        {/* <div
          style={{
            textAlign: "left",
            width: "100%",
            backgroundColor: "#fffacc",
            border: "2px solid #fff176",
            padding: "8px",
            margin: "0 0 24px 0",
            fontSize: "12px",
          }}
        >
          Este é o novo visual proposto para melhorar sua experiência de
          navegação. Caso tenha dificuldades para visualizar a pesquisa,{" "}
          <Link
            to={`https://printpdf.report.inquest.tech/#/report/${
              params.reportId
            }${params.targetId ? "/target/" + params.targetId : ""}`}
          >
            clique aqui para acessar o visual anterior
          </Link>
          . Você também pode entrar em contato conosco pelo nosso canal no{" "}
          <Link
            to={"https://web.whatsapp.com/send?phone=5511948889788"}
            target="_blank"
          >
            WhatsApp
          </Link>
          .
        </div> */}
        <Row justify={"space-between"} gutter={[16, 16]}>
          <Col>
            <Button
              className="outlined-button"
              onClick={() => {
                navigate("/dashboard");
              }}
              icon={<Icons icon="left-arrow-circle" />}
            >
              Voltar
            </Button>
          </Col>
          {profile?.is_staff || profile?.is_superuser ? (
            <Col>
              <Button
                className="outlined-button"
                onClick={() => {
                  navigate(`/dashboard/report/${params.reportId}/status`);
                }}
                icon={<Icons icon="clock" />}
              >
                Página de status
              </Button>
            </Col>
          ) : null}
        </Row>
        {state.data.report?.id ? (
          // <Row className="report-title-info" justify={"space-between"}>
          //   <Col xs={24} sm={24} md={24} lg={14}>
          //     <div className="case-name-tile">
          //       <span>Solicitação: </span>
          //       {state.data.report.case_name}
          //     </div>
          //     <div>
          //       <span>Serviço contratado: </span>
          //       {state.data.report.case_type_display}
          //     </div>
          //     <div>
          //       <span>Data da solicitação: </span>
          //       {state.data.report.created_at}
          //     </div>
          //     <div>
          //       <span>Status: </span>
          //       <span><StatusIcon status={state.data.report.status} isAdmUser={profile?.is_staff || profile?.is_superuser}/></span>
          //     </div>
          //     <div>
          //       <span>Contexto: </span>
          //       {state.data.report.context_display}
          //     </div>
          //     {state.data.report?.targets?.length > 0 ||
          //     state.data.report?.context_values?.length > 0 ||
          //     state.data.report?.docs_products?.length > 0 ? (
          //       <div>
          //         <Collapse
          //           className="collapse-report-context"
          //           size="small"
          //           ghost
          //           items={[
          //             {
          //               key: "1",
          //               label: "Ver detalhes",
          //               children: (
          //                 <div className="report-context-info">
          //                   {state.data.report?.targets?.length > 0 ? (
          //                     <div>
          //                       <label>Pesquisados</label>
          //                       <ul>
          //                         {state.data.report?.targets?.map(
          //                           (item, idx) => (
          //                             <li key={idx}>
          //                               {beautifyCpfCnpj(item.cpf_cnpj)}
          //                               {item.name ? `, ${item.name}` : ""}
          //                             </li>
          //                           )
          //                         )}
          //                       </ul>
          //                     </div>
          //                   ) : null}

          //                   {state.data.report?.docs_products?.length > 0 ? (
          //                     <div className="docs-products-info">
          //                       <label>DOCS</label>
          //                       <ul>
          //                         {state.data.report?.docs_products?.map(
          //                           (item, idx) => (
          //                             <li key={idx}>
          //                               {item.product_display}
          //                               {item.cpf_cnpj
          //                                 ? `, ${beautifyCpfCnpj(
          //                                     item.cpf_cnpj
          //                                   )}`
          //                                 : ""}
          //                               {item.city
          //                                 ? `, CIDADE ${item.city}`
          //                                 : ""}
          //                               {item.uf ? `, ESTADO ${item.uf}` : ""}
          //                               {item.num_matricula
          //                                 ? `, MATRÍCULA ${item.num_matricula}`
          //                                 : ""}
          //                               {item.qtd && item.qtd > 1
          //                                 ? `, ${item.qtd} AQUISIÇÕES`
          //                                 : ""}
          //                             </li>
          //                           )
          //                         )}
          //                       </ul>
          //                     </div>
          //                   ) : null}

          //                   {state.data.report?.context_values?.map(
          //                     (item, idx) => (
          //                       <div key={idx}>
          //                         <label>{item.label}</label>
          //                         {item.values ? (
          //                           <ul>
          //                             {item.values.map((value, i) => (
          //                               <li key={i}>{value}</li>
          //                             ))}
          //                           </ul>
          //                         ) : (
          //                           <span> {item.value}</span>
          //                         )}
          //                       </div>
          //                     )
          //                   )}
          //                 </div>
          //               ),
          //             },
          //           ]}
          //         />
          //       </div>
          //     ) : null}
          //   </Col>
          // </Row>
          <Row>
            <Col style={{ width: "100%" }}>
              <Row className="report-title-info">
                <div className="case-name-tile">
                  {state.data.report.case_name}
                    <StatusIcon
                      status={state.data.report.status}
                      isAdmUser={profile?.is_staff || profile?.is_superuser}
                    />
                </div>
              </Row>
              <Row style={{ width: "100%" }} justify="space-between">
                <Row>
                  <div className="box-totalizer">
                    <div>Data da solicitação</div>
                    <div>{state.data.report.created_at}</div>
                  </div>
                  <div className="box-totalizer">
                    <div>Serviço</div>
                    <div>{state.data.report.case_type_display}</div>
                  </div>
                  <div className="box-totalizer">
                    <div>Qtd. de Pesquisados</div>
                    <div>{state.data.report?.targets?.length}</div>
                  </div>
                </Row>
                <Row justify="space-between">
                  {state.data.report?.case_type === "smart_pro" && (
                    <>
                      <BlueSquareButton>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                          onClick={() => setShowParecerModal(true)}
                        >
                          <VideoSvg />
                          Assistir Parecer
                        </div>
                      </BlueSquareButton>
                      <div style={{ width: 10 }} />
                    </>
                  )}
                  <BlueSquareButton
                    onClick={() =>
                      navigate(`/report/${state.data.report?.id}/graph`)
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <GraphSvg />
                      Relacionamentos
                    </div>
                  </BlueSquareButton>
                </Row>
              </Row>
            </Col>
          </Row>
        ) : (
          <LoadingSkeleton />
        )}

        {state.data.report?.id &&
        !["docs", "mapa_calor"].includes(state.data.report?.case_type) ? (
          <>
            <Row className="report-targets-info">
              {state.data.report?.targets && (
                <Col span={24}>
                  {" "}
                  <div className="label-select">
                    Escolha um pesquisado para visualizar:
                  </div>
                </Col>
              )}
              <Col span={24}>
                <Select
                  showSearch
                  defaultValue={params.targetId}
                  placeholder={`${state.data.report?.targets?.length} pesquisado(s)`}
                  optionFilterProp="children"
                  onChange={(id, option) => {
                    // const target = state.data.report?.targets?.filter(x => x.id === id)[0]
                    // setTarget(target)
                    navigate(
                      `/dashboard/report/${params.reportId}/target/${id}`
                    );
                  }}
                  // onSearch={() => {}}
                  filterOption={(input, option) => {
                    const children = option?.label.props.children;
                    const label = `${children[2]} ${children[3]}`.replace(
                      /[./-]/g,
                      ""
                    );
                    return label.toLowerCase().includes(input.toLowerCase());
                  }}
                  options={state.data.report?.targets?.map((item, idx) => ({
                    key: item.id,
                    value: item.id,
                    label: (
                      <span className="select-target-item">
                        <span className="counter">{idx + 1}</span>
                        {item.status !== "completed" ? (
                          <Icons icon="loading" spin />
                        ) : (
                          <Icons icon="checked-circle" />
                        )}
                        {beautifyCpfCnpj(item.cpf_cnpj)}
                        {item.name ? " - " + item.name : ""}
                      </span>
                    ),
                  }))}
                />
              </Col>
            </Row>
          </>
        ) : (
          <></>
        )}
        <div className="report-view-data">
          {state.data.report?.case_type === "docs" ||
          state.data.report?.case_type === "mapa_calor" ? (
            <DocPage reportId={state.data.report!.id} />
          ) : target?.id ? (
            <TargetPage reportId={state.data.report!.id} targetId={target.id} />
          ) : null}
        </div>

         <Modal
              className="parecer-modal"
              title="Parecer"
              open={showParecerModal}
              onCancel={() => setShowParecerModal(false)}
              footer={null}
              width={"70%"}
              >
                 <Col
                  className="orientations-section"
                  style={{width: "100%"}}
                >
                  <Col>
                    <ol>
                      {state.data.report?.video_description_embed ? (
                        !state.data.report?.video_description_password ? (
                          <li>
                            Primeiramente, para entender todos os dados
                            apresentados, sugerimos assistir ao vídeo do parecer
                            técnico anexado em seguida.
                          </li>
                        ) : (
                          <li>
                            Primeiramente, para entender todos os dados
                            apresentados, sugerimos assistir ao vídeo do parecer
                            técnico anexado em seguida. Insira a senha{" "}
                            <span
                              className="text-highlight clicable"
                              onClick={copyPasswordVideo}
                            >
                              {
                                state.data.report
                                  ?.video_description_password
                              }
                            </span>
                            <span className="copy-icon">
                              <Icons icon="copy" onClick={copyPasswordVideo} />
                            </span>{" "}
                            para iniciar o vídeo;
                          </li>
                        )
                      ) : state.data.report?.video_description_link ? (
                        state.data.report?.video_description_password ? (
                          <li>
                            Primeiramente, para entender todos os dados
                            apresentados, sugerimos acessar o{" "}
                            <span
                              className="text-highlight clicable"
                              onClick={() => {
                                window.open(
                                  state.data.report
                                    ?.video_description_link,
                                  "__blank"
                                );
                              }}
                            >
                              {" "}
                              link do parecer técnico{" "}
                            </span>{" "}
                            para assistir ao vídeo de apresentação. Insira a
                            senha{" "}
                            <span
                              className="text-highlight clicable"
                              onClick={copyPasswordVideo}
                            >
                              {
                                state.data.report
                                  ?.video_description_password
                              }
                            </span>
                            <span className="copy-icon">
                              <Icons icon="copy" onClick={copyPasswordVideo} />
                            </span>{" "}
                            para inciar o vídeo;
                          </li>
                        ) : (
                          <li>
                            Primeiramente, para entender todos os dados
                            apresentados, sugerimos acessar o{" "}
                            <span
                              className="text-highlight clicable"
                              onClick={() => {
                                window.open(
                                  state.data.report
                                    ?.video_description_link,
                                  "__blank"
                                );
                              }}
                            >
                              link do parecer técnico
                            </span>{" "}
                            para assistir ao vídeo de apresentação;
                          </li>
                        )
                      ) : null}
                      {!["docs", "mapa_calor"].includes(state.data.report?.case_type ?? "") ? (
                      <li>
                        Navegue em todos os pesquisados disponíveis neste REPORT
                        selecionando acima o pesquisado(a) a visualizar;
                      </li> ) : null}
                      <li>
                        Obtenha todas as pesquisas prontas em formato EXCEL
                        clicando em{" "}
                        <span className="text-highlight">
                          <Link to={buildLink("attachments")} onClick={() => {
                              window.scrollTo(0, 0);
                          }} target="_self" >Arquivos e anexos</Link>
                        </span>.
                      </li>
                    </ol>
                  </Col>
                  {state.data.report?.video_description_embed ? (
                    <Col>
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            state.data.report?.video_description_embed,
                        }}
                      ></div>
                    </Col>
                  ) : null}
                </Col>
              </Modal>
      </div>
    </>
  );
};

export default ReportPage;
