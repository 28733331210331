import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getTargetThunk, selectTargetPageState } from "./slice";
import StateStatus from "../../../../utils/stateStatus";
import { TabContentVeiculos } from "./tabs/veiculos";
import { TabContentProcessos } from "./tabs/processos";
import Carousel from "react-bootstrap/Carousel";
import { Badge, Col, Row, Select, Skeleton, Menu, Button, Tooltip } from "antd";
import { TabContentReceitaFederal } from "./tabs/receita_federal__grupo_economico_cnpj";
import { TabContentInfoCNPJ } from "./tabs/receita_federal__info_cnpj";
import { ServicesEnum } from "../../../../utils/services";
import { TabContentResumo } from "./tabs/resumo";
import { TabContentPartnership } from "./tabs/receita_federal_partnerships";
import { TabContentInfoCPF } from "./tabs/credilink__info_cpf";
import { useLocation, useNavigate } from "react-router";
import { TabContentTST } from "./tabs/tst";
import { TabContentParecer } from "./tabs/parecer";
import { TabContentSintegraPJ } from "./tabs/sintegra__sintegra_pj";
import { TabContentSintegraPF } from "./tabs/sintegra__sintegra_pf";
import { TabContentJucesp } from "./tabs/jucesp";
import { TabContentBoavista } from "./tabs/boavista";
import { TabContentFileAttachments } from "./tabs/file_attachments";
import { TabContentVideoExplain } from "./tabs/video_explain";
import { selectReportPageState } from "../report-page/slice";
import { TabContentEmpresarioInvividual } from "./tabs/empresario_individual";
import { TabContentNoticiasMidiasSites } from "./tabs/noticias_midias_sites";
import { TabContentProcessosTramitacao } from "./tabs/processos_tramitacao";
import { TabContentProcessosArquivados } from "./tabs/processos_arquivados";
import { TabContentMarcasPatentes } from "./tabs/marcas_patentes";
import { TabContentAveriguacaoProcessos } from "./tabs/processos__averiguacao";
import { Link } from "react-router-dom";
import { TabContentImoveis } from "./tabs/imoveis";
import Icons from "../../../../common/components/Icons";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  FileOutlined,
} from "@ant-design/icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.scss";

const BadgeNumber = ({ count, isLoading }) => {
  if (!count || count <= 0)
    return <Badge color="gray" size="small" count={0} />;
  if (isLoading) return <Icons icon="loading" spin />;
  return <Badge color="blue" size="small" count={count} overflowCount={999} />;
};

const TargetPage = ({ reportId, targetId }) => {
  const state = useSelector(selectTargetPageState);
  const reportState = useSelector(selectReportPageState);
  const dispatch = useDispatch();
  const target = state.data.target;
  const services = target?.services;
  const navigate = useNavigate();
  const location = useLocation();
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [resumoTab, setResumoTab] = useState<React.JSX.Element>();
  const [cadastralTab, setCadastralTab] = useState<React.JSX.Element>();
  const [activeTab, setActiveTab] = useState<string>();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const menuRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsCollapsed(true);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    if (reportState.data.report?.case_type === "smart_pro") {
      console.log("show video presentation");
      // TODO: show video presentation
    }
  }, [reportId]);

  useEffect(() => {
    dispatch(getTargetThunk(targetId));
  }, [targetId]);

  // fix Carousel height bug
  useEffect(() => {
    if (state.status.getServiceData === StateStatus.succeeded) {
      setTimeout(() => {
        const rootCarousel = document.querySelector(".MuiPaper-root");
        // @ts-ignore
        if (rootCarousel?.parentElement) {
          // @ts-ignore
          let elem = rootCarousel;
          for (let i = 0; i < 5; i++) {
            // @ts-ignore
            if (elem.parentElement?.style) {
              // @ts-ignore
              elem.parentElement.style.height =
                rootCarousel.offsetHeight.toString() + "px";
              elem = elem.parentElement;
            }
          }
        }
      }, 2000);
    }
  }, [state.status.getServiceData]);

  useEffect(() => {
    if (state.status.getTarget === StateStatus.succeeded) {
      changeActiveTabFromParams();
    }
  }, [state.status.getTarget]);

  // handle window size to hide Carousel
  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const changeActiveTabFromParams = () => {
    const query = new URLSearchParams(location.search);
    const tab = query.get("tab");
    const defaultTab = "resumo";
    const active = tab ? tab.toString().toLowerCase() : defaultTab;

    items.forEach((x, idx) => {
      if (x.key === active) setCarouselIndex(idx);
    });
    setActiveTab(active);
  };
  

  const parseDate = (dateStr) => {
    const [data, hora] = dateStr.split(" ");
    const [dia, mes, ano] = data.split("/").map(Number);
    const [horas, minutos] = hora.split(":").map(Number);

    return new Date(ano, mes - 1, dia, horas, minutos);
  };

  interface TabNew {
    key: string;
    label: ReactNode;
    order?: number | undefined;
    count: number | undefined | null;
    status?: "loading" | "completed" | "idle" | "released" | "error" | string;
    can_render?: boolean | undefined;
    children: ReactNode;
  }

  const items: TabNew[] = useMemo(() => {
    if (state.status.getTarget !== StateStatus.succeeded || !target) return [];

    setResumoTab(<TabContentResumo />);
    if (services?.receita_federal && target?.cpf_cnpj?.length > 11) {
      setCadastralTab(<TabContentInfoCNPJ />);
    } else if (services?.credilink && target?.cpf_cnpj?.length <= 11) {
      setCadastralTab(<TabContentInfoCPF />);
    }

    let lst = [
      {
        key: "resumo",
        label: "Resumo",
        icon: <FileOutlined />,
        order: 1,
        count: null,
        status: target.status,
        can_render: true,
        children: resumoTab,
      },
      // {
      //   key: "orientacoes",
      //   label: "Orientações",
      //   order: 2,
      //   count: null,
      //   status: target.status,
      //   can_render: true,
      //   children: <TabContentVideoExplain />,
      // },
      {
        key: "attachments",
        label: "Arquivos e anexos",
        order: 3,
        count:
          (reportState.data.files?.report_files?.filter(
            (x) => x.status === "completed"
          )?.length ?? 0) +
          (reportState.data.files?.target_files?.filter(
            (x) => x.xlsx?.status === "completed" && x.id == targetId
          )?.length ?? 0) +
          (reportState.data.files?.target_files?.filter(
            (x) => x.pdf?.status === "completed" && x.id == targetId
          )?.length ?? 0),
        status: target.status,
        can_render: true,
        children: <TabContentFileAttachments />,
      },
      {
        key: ServicesEnum.parecer.name,
        label: "Parecer",
        order: 4,
        count: 1,
        status: services?.parecer?.service_status,
        can_render: Boolean(services?.parecer),
        children: <TabContentParecer />,
      },
      {
        key: "dados_cadastrais_ecossistema",
        label: "Dados cadastrais e pessoas relacionadas",
        order: 5,
        count:
          1 + (services?.ecosystem?.counters?.total_relationships_count ?? 0),
        status:
          services?.credilink?.service_status === "completed" &&
          services?.ecosystem?.service_status === "completed"
            ? "completed"
            : "loading",
        can_render: Boolean(
          services?.receita_federal && target?.cpf_cnpj?.length > 11
        ),
        children: <TabContentInfoCNPJ />,
      },
      {
        key: "dados_cadastrais_ecossistema",
        label: "Dados cadastrais e pessoas relacionadas",
        order: 5,
        count:
          1 + (services?.ecosystem?.counters?.total_relationships_count ?? 0),
        status:
          services?.receita_federal?.service_status === "completed" &&
          services?.ecosystem?.service_status === "completed"
            ? "completed"
            : "loading",
        can_render: Boolean(
          services?.credilink && target?.cpf_cnpj?.length <= 11
        ),
        children: <TabContentInfoCPF />,
      },
      {
        key: "score_protestos_negativacoes",
        label: "Score, Protestos e Negativações",
        count:
          (services?.boavista?.counters?.score_risco_6_meses ? 1 : 0) +
          (services?.boavista?.counters?.total_debitos_qtd ?? 0) +
          (services?.boavista?.counters?.protestos_qtd ?? 0) +
          (services?.cenprot?.counters?.total_debitos_qtd ?? 0),
        status: services?.boavista?.service_status,
        can_render: Boolean(services?.boavista),
        children: <TabContentBoavista />,
      },
      {
        key: ServicesEnum.receita_federal.empresas_cpf_cnpj_e_socio.name,
        label: "Participações societárias",
        count: services?.receita_federal?.counters?.empresas_socio_qtd,
        status: services?.receita_federal?.service_status,
        can_render: Boolean(services?.receita_federal),
        children: (
          <TabContentPartnership
            sectionKey={
              ServicesEnum.receita_federal.empresas_cpf_cnpj_e_socio.name
            }
          />
        ),
      },
      {
        key: ServicesEnum.receita_federal.filiais_do_cnpj.name,
        label: "Filiais",
        count: services?.receita_federal?.counters?.filiais_qtd,
        status: services?.receita_federal?.service_status,
        can_render: Boolean(
          services?.receita_federal && target?.cpf_cnpj?.length > 11
        ),
        children: (
          <TabContentPartnership
            sectionKey={ServicesEnum.receita_federal.filiais_do_cnpj.name}
          />
        ),
      },
      {
        key: ServicesEnum.receita_federal
          .empresarios_individuais_possiveis_do_cpf.name,
        label: "Empresários individuais",
        count:
          (services?.receita_federal?.counters
            ?.empresarios_individuais_possiveis_qtd ?? 0) +
          (services?.receita_federal?.counters
            ?.empresarios_individuais_confirmados_qtd ?? 0),
        status: services?.receita_federal?.service_status,
        can_render: Boolean(
          services?.receita_federal && target?.cpf_cnpj?.length <= 11
        ),
        children: <TabContentEmpresarioInvividual />,
      },
      {
        key: ServicesEnum.receita_federal.grupo_economico_cnpj.name,
        label: "Grupo econômico",
        count: services?.receita_federal?.counters?.grupo_economico_qtd,
        status: services?.receita_federal?.service_status,
        can_render: Boolean(
          services?.receita_federal && target?.cpf_cnpj?.length > 11
        ),
        children: (
          <TabContentReceitaFederal
            sectionKey={ServicesEnum.receita_federal.grupo_economico_cnpj.name}
          />
        ),
      },
      {
        key: ServicesEnum.veiculos.veiculos.name,
        label: "Veículos",
        count: services?.veiculos?.counters?.total_veiculos_count,
        status: services?.veiculos?.service_status,
        can_render: Boolean(services?.veiculos),
        children: <TabContentVeiculos />,
      },
      {
        key: ServicesEnum.imoveis.name,
        label: "Imóveis",
        count: services?.imoveis?.counters?.total_imoveis_count,
        status: services?.imoveis?.service_status,
        can_render: Boolean(
          (services?.imoveis &&
            (services?.imoveis?.counters?.total_imoveis_count ?? 0) > 0) ||
            parseDate(state.data.target?.created_at) >= new Date("2025-01-10")
        ),
        children: <TabContentImoveis />,
      },
      {
        key: "noticias_midias_sites",
        label: "Sites e Mídias Sociais",
        count:
          (services?.whois?.counters?.total_dominios_count ?? 0) +
          (services?.gnews?.counters?.total_noticias_count ?? 0) +
          (services?.socialmedia?.counters?.total_social_medias_count ?? 0),
        status:
          services?.whois?.service_status === "completed" &&
          services?.gnews?.service_status === "completed" &&
          services?.socialmedia?.service_status === "completed"
            ? "completed"
            : "loading",
        can_render: Boolean(
          services?.whois || services?.gnews || services?.socialmedia
        ),
        children: <TabContentNoticiasMidiasSites />,
      },
      {
        key: ServicesEnum.jucesp.empresas.name,
        label: "Atos societários",
        count: services?.jucesp?.counters?.total_empresas_count,
        status: services?.jucesp?.service_status,
        can_render: Boolean(services?.jucesp && target?.cpf_cnpj?.length > 11),
        children: <TabContentJucesp />,
      },
      {
        key: ServicesEnum.tst.debitos_trabalhistas.name,
        label: "Débitos Trabalhistas",
        count: services?.tst?.counters?.total_processos_count,
        status: services?.tst?.service_status,
        can_render: Boolean(services?.tst),
        children: <TabContentTST />,
      },
      {
        key: "marcas_patentes",
        label: "Marcas e Patentes",
        count:
          (services?.inpi?.counters?.total_marcas_count ?? 0) +
          (services?.inpi?.counters?.total_patentes_count ?? 0),
        status: services?.inpi?.service_status,
        can_render: Boolean(services?.inpi),
        children: <TabContentMarcasPatentes />,
      },
      {
        key: ServicesEnum.sintegra.sintegra_pf.name,
        label: "Sintegra",
        count: services?.sintegra?.counters?.total_results_count,
        status: services?.sintegra?.service_status,
        can_render: Boolean(
          services?.sintegra && target?.cpf_cnpj?.length <= 11
        ),
        children: <TabContentSintegraPF />,
      },
      {
        key: ServicesEnum.sintegra.sintegra_pj.name,
        label: "Sintegra",
        count: services?.sintegra?.counters?.total_results_count,
        status: services?.sintegra?.service_status,
        can_render: Boolean(
          services?.sintegra && target?.cpf_cnpj?.length > 11
        ),
        children: <TabContentSintegraPJ />,
      },
      {
        key: ServicesEnum.processos.processos_para_aquisicao_copia.name,
        label: "Processos relevantes",
        count: services?.processos?.counters?.processos_relevantes_qtd,
        status: services?.processos?.service_status,
        can_render: Boolean(services?.processos),
        children: (
          <TabContentProcessos
            sectionKey={
              ServicesEnum.processos.processos_para_aquisicao_copia.name
            }
          />
        ),
      },
      {
        key: ServicesEnum.processos.averiguacao_processos.name,
        label: "Averiguação sobre os processos",
        count:
          reportState.data.report?.case_type === "preventivo" &&
          services?.processos?.counters?.data?.processos_ativos
            ? 1
            : 0,
        status: services?.processos?.service_status,
        can_render: Boolean(
          reportState.data.report?.case_type === "preventivo"
        ),
        children: <TabContentAveriguacaoProcessos />,
      },
      {
        key: "processos_tramitacao",
        label: "Processos em tramitação",
        count:
          (services?.processos?.counters?.data?.processos_ativos
            ?.qtd_por_cpf_cnpj?.total ?? 0) +
          (services?.processos?.counters?.data?.processos_ativos?.qtd_por_nome
            ?.total ?? 0),
        status: services?.processos?.service_status,
        can_render: Boolean(services?.processos),
        children: <TabContentProcessosTramitacao />,
      },
      {
        key: "processos_arquivados",
        label: "Processos arquivados",
        count:
          (services?.processos?.counters?.data?.processos_inativos?.qtd_por_nome
            ?.total ?? 0) +
          (services?.processos?.counters?.data?.processos_inativos
            ?.qtd_por_cpf_cnpj?.total ?? 0),
        status: services?.processos?.service_status,
        can_render: Boolean(services?.processos),
        children: <TabContentProcessosArquivados />,
      },
    ];

    lst = lst.filter((x) => x.can_render);

    if (windowDimensions.width >= 800) {
      lst.sort((a, b) => {
        // Se ambos objetos têm a propriedade "order", compare por "order"
        if (a.order !== undefined && b.order !== undefined) {
          return a.order - b.order;
        }
        // Se apenas um dos objetos tem "order", ele vem primeiro
        if (a.order !== undefined) {
          return -1;
        }
        if (b.order !== undefined) {
          return 1;
        }
        // Se nenhum tem "order", compare por "count" (maior primeiro)
        return (b.count ?? 0) - (a.count ?? 0);
      });
    }

    return lst;
  }, [
    state.status.getTarget,
    state.status.getServiceCounters,
    services?.veiculos?.counters,
    services?.tst?.counters,
    services?.whois?.counters,
    services?.socialmedia?.counters,
    services?.processos?.counters,
    services?.receita_federal?.counters,
    services?.ecosystem?.counters,
    services?.jucesp?.counters,
    services?.parecer?.counters,
    services?.boavista?.counters,
    services?.cenprot?.counters,
    services?.gnews?.counters,
    services?.sigef?.counters,
    services?.sintegra?.counters,
    services?.inpi?.counters,
  ]);

  useEffect(() => {
    changeActiveTabFromParams();
  }, [
    location.search,
    location.hash,
    state.status.getTarget,
    state.status.getServiceCounters,
    services?.veiculos?.counters,
    services?.tst?.counters,
    services?.whois?.counters,
    services?.socialmedia?.counters,
    services?.processos?.counters,
    services?.receita_federal?.counters,
    services?.ecosystem?.counters,
    services?.jucesp?.counters,
    services?.parecer?.counters,
    services?.boavista?.counters,
    services?.cenprot?.counters,
    services?.gnews?.counters,
    services?.sigef?.counters,
    services?.sintegra?.counters,
    services?.inpi?.counters,
  ]);

  const carousel = items.map((x, idx) =>
    x.can_render ? (
      <Carousel.Item id={x.key} key={x.key}>
        {x.children}
      </Carousel.Item>
    ) : null
  );

  const handleSelect = (selectedIndex) => {
    setCarouselIndex(selectedIndex);
    setActiveTab(items[selectedIndex].key);
    navigate(`${location.pathname}?tab=${items[selectedIndex].key}`);
  };

  return (
    <div className="target-view-page">
      {state.status.getTarget === StateStatus.succeeded ? (
        <Row justify={"center"}>
          {/* <Col span={24}>
            <div className="label-select">
              Selecione os dados para visualizar:
            </div>
            <Select
              showSearch
              className="select-section"
              value={activeTab}
              placeholder={"Selecionar dados..."}
              optionFilterProp="children"
              onChange={(id, option) => {
                items.forEach((x, idx) => {
                  if (x.key === id) setCarouselIndex(idx);
                });
                setActiveTab(id);
                navigate(`${location.pathname}?tab=${id}`);
              }}
              filterOption={(input, option) => {
                const children = option?.label?.props.children;
                const label = `${children[0]}`.replace(/[./-]/g, "");
                return label.toLowerCase().includes(input.toLowerCase());
              }}
              options={items.map((item, key) => ({
                key: item.key,
                value: item.key,
                label: (
                  <span className="select-section-item">
                    <span>{item.label}</span>{" "}
                    {item.count !== null ? (
                      <BadgeNumber
                        count={item.count}
                        isLoading={["loading", "error"].includes(
                          item.status ?? ""
                        )}
                      />
                    ) : null}
                  </span>
                ),
              }))}
            />
          </Col> */}
          <Row
            style={{ width: "100%", backgroundColor: "white", padding: "10px" }}
          >
            {/* {windowDimensions.width < 768 ? (
                    <Carousel slide={true} interval={null} variant="dark" activeIndex={carouselIndex} onSelect={handleSelect}>
                      {carousel}
                    </Carousel>
                  ) : null}  */}
            <Col span={24} style={{ position: "relative", zIndex: 1 }}>
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  
                }}
                ref={menuRef}
              >
                <Tooltip title={isCollapsed ? "Abrir menu" : "Fechar menu"}>
                  <Button
                    type="primary"
                    onClick={() => {
                      setIsCollapsed((old) => !old);
                      setIsMenuOpen((old) => !old);
                    }}
                    style={{ marginBottom: 16 }}
                  >
                   MENU
                  </Button>
                </Tooltip>
                {!isCollapsed && (
                  <Menu
                    defaultSelectedKeys={[activeTab ?? ""]}
                    // mode="horizontal"
                    style={{ boxShadow: "0 1px 6px rgba(0, 0, 0, 0.23)" }}
                    selectedKeys={[activeTab ?? ""]}
                    inlineCollapsed={isCollapsed}
                    items={items.map((x, key) => ({
                      label: (
                        <>
                          {x.label}{" "}
                          {x.count !== null ? (
                            <BadgeNumber
                              count={x.count}
                              isLoading={["loading", "error"].includes(
                                x.status ?? ""
                              )}
                            />
                          ) : null}
                        </>
                      ),
                      key: x.key,
                    }))}
                    onClick={({ key }) => {
                      setIsCollapsed(true);
                      setActiveTab(key);
                      navigate(`${location.pathname}?tab=${key}`);
                    }}
                  />
                )}
              </div>
            </Col>
            <Col span={24} style={{paddingTop: "35px"}}>
              {activeTab && (
                <div style={{ backgroundColor: "white", width: "100%" }}>
                  {items.filter((item) => item.key === activeTab)[0].children}
                </div>
              )}
            </Col>
            {/* <Tabs
              defaultActiveKey={activeTab}
              activeKey={activeTab}
              onTabClick={(tabKey) => {
                setActiveTab(tabKey);
                navigate(`${location.pathname}?tab=${tabKey}`);
              }}
              // @ts-ignore
              items={items.map((x, key) => ({
                label: (
                  <>
                    {x.label}{" "}
                    {x.count !== null ? (
                      <BadgeNumber
                        count={x.count}
                        isLoading={["loading", "error"].includes(
                          x.status ?? ""
                        )}
                      />
                    ) : null}
                  </>
                ),
                children: x.children,
                key: x.key,
              }))}
            /> */}
            <div style={{ display: "none" }}>{resumoTab}</div>
            <div style={{ display: "none" }}>{cadastralTab}</div>
          </Row>
        </Row>
      ) : state.status.getTarget === StateStatus.loading ? (
        <div>
          <div className="loading-text">
            <Skeleton active />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export { TargetPage };