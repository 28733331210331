
import "./style.scss";

import {
  Table,
  Row,
  Col,
  Tooltip,
  Form,
  Input,
  Select,
  Button,
  message,
  DatePicker,
} from "antd";
import React, { useEffect } from "react";
import Icons from "../../../../common/components/Icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getListTargetsThunk,
  resetRetryService,
  retryServiceThunk,
  selectMonitoringState,
} from "./slice";
import StateStatus from "../../../../utils/stateStatus";
import { Link } from "react-router-dom";
import localeValues from "antd/locale/pt_BR";
import { HeaderDash } from "../../../../common/components/header";
import { getProfileThunk } from "../../../login/slice";
import { StatusIcon } from "../../../../utils/status";
import dayjs from 'dayjs';
import IconStatus from "../../../target_status_page/components/status_icon";
import { SyncOutlined } from "@ant-design/icons";
import {
  canRequest,
  getLastUpdateTime,
} from "../../../target_status_page/components/target_status_card";
import { beautifyCpfCnpj } from "../../../../utils/formatters";
import { ColumnType } from "antd/lib/table";
import { ServiceType, TargetStatusType } from "./interfaces";

const MonitoringPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(selectMonitoringState);
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getProfileThunk());
    listTargetsFirtsPage();
  }, []);

  useEffect(() => {
    if (state.status.retryService === StateStatus.succeeded) {
      message.success("O serviço será reiniciado...");
      dispatch(resetRetryService());
    }

    if (state.status.retryService === StateStatus.failed) {
      message.success("Erro ao tentar reiniciar o serviço...");
      dispatch(resetRetryService());
    }
  }, [state.status.retryService]);

  const reloadCurrentPage = () => {
    dispatch(
      getListTargetsThunk({
        page: state.data.targets.pagination.page,
        pageSize: state.data.targets.pagination.pageSize,
        filter: state.data.targets.filters.filter,
        status: state.data.targets.filters.status,
        service: state.data.targets.filters.service,
        created_starts_at: state.data.targets.filters.created_starts_at,
        created_ends_at: state.data.targets.filters.created_ends_at,
      })
    );
  };

  const listTargetsFirtsPage = () => {
    dispatch(
      getListTargetsThunk({
        page: 1,
        pageSize: 15,
        filter: null,
        status: state.data.targets.filters.status,
        service: null,
        created_starts_at: state.data.targets.filters.created_starts_at,
        created_ends_at: state.data.targets.filters.created_ends_at,
      })
    );
  };

  const viewReportPage = (report, target) => {
    return "/report/" + report.id + "/target/" + target.id;
  };

  const viewReportStatus = (report) => {
    return "/report/" + report.id + "/status";
  };

  const columnsServices: ColumnType<ServiceType>[] = [
    {
      title: "",
      dataIndex: "case_type_display",
      // responsive: ["lg", "md"],
      width: "30%",
      render: (_, service) => {
        return (
          <Row>
            <Col span={24}>{service.service}</Col>
            <Col span={24} className="info-target">
              Depende de: {Array.from(service.depends_on ??  []).join(", ")}
            </Col>
          </Row>
        );
      },
    },
    {
      title: "",
      dataIndex: "service_status",
      // responsive: ["lg", "md"],
      width: "30%",
      render: (text, service) => (
        <Row>
          <Col span={24}>
            <IconStatus status={text} />
          </Col>
          <Col span={24} className="info-target">
            {service.service_status === "idle" ? (
              canRequest(service, service.ready_services ?? [] ) ? (
                `Apto para executar`
              ) : (
                `Aguardando ${Array.from(service.depends_on ?? []).join(", ")}...`
              )
            ) : service.service_status === "error" ? (
              <span>
                <div>{getLastUpdateTime(service)}</div>
                <div style={{ color: "red" }}>
                  {service.error_message &&
                  (["credilink", "receita_federal"].includes(service.service ?? "") 
                  || service.error_message.includes("FIPE") || service.error_message.includes("OCORRÊNCIAS"))
                    ? service.error_message
                    : ""}
                </div>
              </span>
            ) : service.service_status === "completed" ? (
              service.block_status !== "completed" ? (
                <span
                  style={{
                    color: service.block_status === "error" ? "red" : "blue",
                  }}
                >
                  {service.block_status === "error"
                    ? "Não foi possível gerar HTML"
                    : service.block_status === "loading"
                    ? "Gerando HTML..."
                    : service.block_status === "idle"
                    ? "Criação do HTML não iniciada"
                    : null}
                </span>
              ) : (
                getLastUpdateTime(service)
              )
            ) : (
              getLastUpdateTime(service)
            )}
          </Col>
        </Row>
      ),
    },
    {
      title: "",
      dataIndex: "view",
      align: "center",
      width: "40%",
      // responsive: ["lg", "md"],
      render: (_, service) => (
        <Row gutter={[16, 16]} justify="center">
          {(["error", "idle"].includes(service.service_status?? "") ||
            (["loading"].includes(service.service_status ?? "") &&
              service.delay_minutes && service.delay_minutes >= 15)) &&
          canRequest(service, service.ready_services ?? []) ? (
            <Button
              icon={<SyncOutlined />}
              size={"small"}
              loading={
                state.status.retryService === StateStatus.loading &&
                state.data.retryServiceId === service.id
              }
              onClick={() => {
                dispatch(retryServiceThunk(service.id!));
              }}
            >
              {service.service_status === "idle" ? "Executar" : "Retentar"}
            </Button>
          ) : null}

          {service.service_status === "completed" &&
          service.block_status !== "completed" ? (
            <Button
              icon={<SyncOutlined />}
              size={"small"}
              loading={
                state.status.retryService === StateStatus.loading &&
                state.data.retryServiceId === service.id
              }
              onClick={() => {
                dispatch(retryServiceThunk(service.id!));
              }}
            >
              {service.block_status === "idle" ? "Criar HTML" : "Retentar HTML"}
            </Button>
          ) : null}
        </Row>
      ),
    },
  ];

  const columns = [
    {
      title: "TARGETS E SERVIÇOS NÃO FINALIZADOS",
      dataIndex: "case_name",
      width: "100%",
      render: (_, record) => (
        <>
          <Row>
            {/* <span style={{fontSize: "14px"}}>{record.case_name}</span> */}
            <Col span={24}>
              <Row justify={"space-between"}>
                <Col span={20}>
                  {beautifyCpfCnpj(record.cpf_cnpj)} - {record.name ?? ""}
                </Col>
                <Col span={4}>
                  <Row justify={"space-around"}>
                    <Col>
                      <Tooltip title="Página do report">
                        <Link
                          to={viewReportPage(record.report, record)}
                          target="_blank"
                        >
                          <Icons icon={"eye"} />
                        </Link>
                      </Tooltip>
                    </Col>
                    <Col>
                      <Tooltip title="Página de status">
                        <Link
                          to={viewReportStatus(record.report)}
                          target="_blank"
                        >
                          <Icons icon={"info-circle"} />
                        </Link>
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24} className="info-target">
              <div>Caso: {record.report.case_name} </div>
              <div>Tipo: {record.report.case_type_display}</div>
              <div style={{ marginBottom: "15px" }}>
                <div>Status do caso: <StatusIcon status={record.report.status} isAdmUser={true} /></div>
              </div>
              {/* <div>Status Target: <StatusIcon status={record.target.status} isAdmUser={true} /></div> */}
              <div>
                <Tooltip title="Criado em">
                  <Icons icon={"clock"} /> {record.report.created_at}
                </Tooltip>
              </div>
              <div>
                <Tooltip title="Cliente">
                  <Icons icon={"user-profile"} /> {record.report.client.name}
                </Tooltip>
              </div>
              <div className="hide-on-full-screen"></div>
            </Col>
          </Row>
          <Row className="row-table-services">
            <Col span={24}>
              <Table
                className="table-services"
                rowKey={(record) => record.id!}
                columns={columnsServices}
                dataSource={record.services.map((s) => ({
                  ready_services: record.ready_services,
                  ...s,
                }))}
                locale={localeValues.Table}
                pagination={false}
              />
            </Col>
          </Row>
        </>
      ),
    },
    // {
    //   title: "Serviço contratado",
    //   dataIndex: "case_type_display",
    //   responsive: ["lg", "md"],
    //   width: "20%",
    //   render: (_, record) => {
    //     return (
    //       <span style={{ fontSize: "12px" }}>
    //         {record.report.case_type_display}
    //       </span>
    //     );
    //   },
    // },
    // {
    //   title: "Serviços",
    //   dataIndex: "created_at",
    //   responsive: ["lg", "md"],
    //   width: "12%",
    //   render: (_, record) => {
    //     return (
    //       <span style={{ fontSize: "12px" }}>{record.services?.length}</span>
    //     );
    //   },
    // },
    // {
    //   title: "Data",
    //   dataIndex: "created_at",
    //   responsive: ["lg", "md"],
    //   width: "12%",
    //   render: (_, record) => {
    //     return <span style={{ fontSize: "12px" }}>{record.created_at}</span>;
    //   },
    // },
    // {
    //   title: "Services",
    //   dataIndex: "services",
    //   responsive: ["lg", "md"],
    //   width: "100%",
    //   render: (services) =>
    //     services.map((service) => <div>service.service</div>),
    // },
  ];

  return (
    <>
      <div className="monitoring-page">
        <HeaderDash />
        <div className="monitoring-content">
          <Row justify={"space-between"} gutter={[16, 24]}>
            <Col>
              <Row justify="space-between" gutter={[16, 24]}>
                <Col></Col>
                <Col></Col>
              </Row>
            </Col>
            <Col>
              <div className="box-totalizer">
                <div>Total de targets</div>
                <div>
                  {state.data ? state.data.targets.pagination.totalCount : 0}
                </div>
              </div>
            </Col>
          </Row>
          <div className="buscas-box">
            <Form form={form}>
              <Row justify={"space-between"} gutter={[8, 8]}>
                <Col xs={24} sm={24} md={6} lg={6}>
                  <Form.Item
                    name={"search"}
                    initialValue={state.data.targets.filters.filter}
                  >
                    <Input.Search
                      // value={state.data.filters.filter}
                      placeholder="Buscar..."
                      loading={state.status.listTargets === StateStatus.loading}
                      enterButton={true}
                      allowClear={true}
                      // onPressEnter={(event) => {
                      //   dispatch(
                      //     getListTargetsThunk({
                      //       page: 1,
                      //       pageSize: state.data.targets.pagination.pageSize,
                      //       filter: event.target.value,
                      //       status: state.data.targets.filters.status,
                      //       service: state.data.targets.filters.service,
                      //       created_starts_at:
                      //         state.data.targets.filters.created_starts_at,
                      //       created_ends_at:
                      //         state.data.targets.filters.created_ends_at,
                      //     })
                      //   );
                      // }}
                      onSearch={(value) => {
                        dispatch(
                          getListTargetsThunk({
                            page: 1,
                            pageSize: state.data.targets.pagination.pageSize,
                            filter: value,
                            status: state.data.targets.filters.status,
                            service: state.data.targets.filters.service,
                            created_starts_at:
                              state.data.targets.filters.created_starts_at,
                            created_ends_at:
                              state.data.targets.filters.created_ends_at,
                          })
                        );
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8}>
                  <Form.Item name={"created_at"} label={"Criado em"}>
                    <DatePicker.RangePicker
                      format="DD-MM-YYYY"
                      maxDate={dayjs(new Date())}
                      onChange={(dates, datesStr) => {
                        dispatch(
                          getListTargetsThunk({
                            page: 1,
                            pageSize: state.data.targets.pagination.pageSize,
                            filter: state.data.targets.filters.filter,
                            status: state.data.targets.filters.status,
                            service: state.data.targets.filters.service,
                            created_starts_at: datesStr[0],
                            created_ends_at: datesStr[1],
                          })
                        );
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4}>
                  <Form.Item
                    name="service"
                    label={"Serviço"}
                    initialValue={state.data.targets.filters.service}
                  >
                    <Select
                      allowClear={true}
                      onClear={() => {
                        dispatch(
                          getListTargetsThunk({
                            page: 1,
                            pageSize: state.data.targets.pagination.pageSize,
                            filter: state.data.targets.filters.filter,
                            status: state.data.targets.filters.status,
                            service: null,
                            created_starts_at:
                              state.data.targets.filters.created_starts_at,
                            created_ends_at:
                              state.data.targets.filters.created_ends_at,
                          })
                        );
                      }}
                      onChange={(value) => {
                        dispatch(
                          getListTargetsThunk({
                            page: 1,
                            pageSize: state.data.targets.pagination.pageSize,
                            filter: state.data.targets.filters.filter,
                            status: state.data.targets.filters.status,
                            service: value,
                            created_starts_at:
                              state.data.targets.filters.created_starts_at,
                            created_ends_at:
                              state.data.targets.filters.created_ends_at,
                          })
                        );
                      }}
                    >
                      <Select.Option value="credilink">credilink</Select.Option>
                      <Select.Option value="ecosystem">ecosystem</Select.Option>
                      <Select.Option value="receita_federal">
                        receita_federal
                      </Select.Option>
                      <Select.Option value="processos">processos</Select.Option>
                      <Select.Option value="jucesp">jucesp</Select.Option>
                      <Select.Option value="gnews">gnews</Select.Option>
                      <Select.Option value="imoveis">imoveis</Select.Option>
                      <Select.Option value="properties">
                        properties
                      </Select.Option>
                      <Select.Option value="inpi">inpi</Select.Option>
                      <Select.Option value="pep">pep</Select.Option>
                      <Select.Option value="sigef">sigef</Select.Option>
                      <Select.Option value="sintegra">sintegra</Select.Option>
                      <Select.Option value="veiculos">veiculos</Select.Option>
                      <Select.Option value="whois">whois</Select.Option>
                      <Select.Option value="boavista">boavista</Select.Option>
                      <Select.Option value="serasa">serasa</Select.Option>
                      <Select.Option value="cenprot">cenprot</Select.Option>
                      <Select.Option value="tst">tst</Select.Option>
                      <Select.Option value="socialmedia">
                        socialmedia
                      </Select.Option>
                      <Select.Option value="parecer">parecer</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={5} lg={5}>
                  <Form.Item
                    name="status"
                    label={"Status serviço"}
                    initialValue={state.data.targets.filters.status}
                  >
                    <Select
                      allowClear={true}
                      onClear={() => {
                        dispatch(
                          getListTargetsThunk({
                            page: 1,
                            pageSize: state.data.targets.pagination.pageSize,
                            filter: state.data.targets.filters.filter,
                            status: null,
                            service: state.data.targets.filters.service,
                            created_starts_at:
                              state.data.targets.filters.created_starts_at,
                            created_ends_at:
                              state.data.targets.filters.created_ends_at,
                          })
                        );
                      }}
                      onChange={(value) => {
                        dispatch(
                          getListTargetsThunk({
                            page: 1,
                            pageSize: state.data.targets.pagination.pageSize,
                            filter: state.data.targets.filters.filter,
                            status: value,
                            service: state.data.targets.filters.service,
                            created_starts_at:
                              state.data.targets.filters.created_starts_at,
                            created_ends_at:
                              state.data.targets.filters.created_ends_at,
                          })
                        );
                      }}
                    >
                      <Select.Option value="idle">Parado</Select.Option>
                      <Select.Option value="error">Erro</Select.Option>
                      <Select.Option value="loading">Carregando</Select.Option>
                      <Select.Option value="released">Liberado</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={1} lg={1}>
                  <Button
                    icon={<SyncOutlined />}
                    size={"middle"}
                    loading={state.status.listTargets === StateStatus.loading}
                    onClick={() => {
                      reloadCurrentPage()
                    }}
                  ></Button>
                </Col>
              </Row>
            </Form>
            <Table
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    // open()
                  },
                };
              }}
              rowKey={(record) => record.id}
              columns={columns}
              dataSource={state.data.targets?.items ?? []}
              loading={state.status.listTargets === StateStatus.loading}
              pagination={{
              showSizeChanger: false,
                showSizeChanger: false,
                current: state.data.targets.pagination?.page,
                pageSize: state.data.targets.pagination?.pageSize,
                total: state.data.targets.pagination?.totalCount,
                onChange: (page, pageSize) => {
                  dispatch(
                    getListTargetsThunk({
                      page: page,
                      pageSize: pageSize,
                      filter: state.data.targets.filters.filter,
                      status: state.data.targets.filters.status,
                      service: state.data.targets.filters.service,
                      created_starts_at:
                        state.data.targets.filters.created_starts_at,
                      created_ends_at:
                        state.data.targets.filters.created_ends_at,
                    })
                  );
                },
              }}
              locale={localeValues.Table}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MonitoringPage;
