import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import StateStatus from "../../utils/stateStatus";
import { passwordForgot } from "./service";
import { ForgotPasswordPayload, ForgotPasswordState } from "./interfaces";


export const forgotPassword = createAsyncThunk<any, ForgotPasswordPayload>(
    "auth/forgot-password",
    async (data: ForgotPasswordPayload) => {
        const response = await passwordForgot(data);
        return response;
    }
);

const initialState: ForgotPasswordState = {
    status: {
        forgotPassword: StateStatus.idle,
    },
    token: null,
    errMessage: null,
};

export const forgotPassSlice = createSlice({
    name: "forgotPass",
    initialState,
    reducers: {
        resetPasswState: (state) => {
            state.errMessage = null;
            state.status.forgotPassword = StateStatus.idle;
            state.token = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(forgotPassword.pending, (state) => {
                state.status.forgotPassword = StateStatus.loading;
            })
            .addCase(forgotPassword.fulfilled, (state) => {
                state.status.forgotPassword = StateStatus.succeeded;
            })
            .addCase(forgotPassword.rejected, (state, action) => {
                state.status.forgotPassword = StateStatus.failed;
                state.errMessage = action.error.message || null;
            });
    },
});

export const { resetPasswState } = forgotPassSlice.actions;

export const selectPasswordState = (state: { forgotPass: ForgotPasswordState }) => state.forgotPass;
