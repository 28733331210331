import api from "../../../../service/api"
import routes from "../../../../service/apiRoutes"


export const getTarget = (targetId) => api.get(routes.target, { params: { target_id: targetId }, noauth: true })
export const getTargetCounters = (targetId) => api.get(`${routes.target}counters`, { params: { target_id: targetId }, noauth: true })
export const getServiceResultData = (params) => api.get(routes.serviceData, { params, noauth: true })
export const getServiceTotalizers = (params) => api.get(routes.serviceTotalizers, { params, noauth: true })
export const getServiceCounters = (params) => api.get(routes.serviceCounters, { params, noauth: true })

