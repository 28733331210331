import React, { FunctionComponent, ReactNode } from "react"
import "./styles.scss"
import { Link } from "react-router-dom"
import { Col, Row } from "antd"
import Icons from "../../../../../../common/components/Icons"

interface CardCounterScoreProps {
    title: string, 
    image: ReactNode,
    toLink: string, 
    labelLink: string | undefined | null,
    isLoading?: boolean,
}

const CardCounterImage: FunctionComponent<CardCounterScoreProps> = ({title, image, toLink, isLoading, labelLink}) => {
    return (
        <div className={`card-counter-video ${isLoading ? 'transparent' : ''}`}>
            <Row justify={"space-between"}>
                <Col>
                    <div className="title">{title}</div>
                </Col>
                {isLoading ? (
                    <Col>
                        <Icons icon="loading" spin={true}/>
                    </Col>
                ) : null}
            </Row>
            <div style={{textAlign: "center"}}>
                {image}
            </div>
            <div className="footer-card">
                <div className="link"><Link to={toLink} onClick={() => window.scrollTo(0, 0)} target="_self" >{labelLink}</Link></div>
            </div>
        </div>
    )
}

export default CardCounterImage