
import React, { useEffect, useState } from "react"

import { Form, message } from "antd"
import Input from "../../common/components/input";

import './style.scss';
// @ts-ignore
import logo from "../../common/images/logo_azul.png";
import { BlueSquareButton } from "../../common/components/button";
import { useDispatch, useSelector } from "react-redux";
import { loginThunk, loginWithTokenThunk, loginSelector, logoutThunk, resetLoginState, getProfileThunk } from "./slice";
import StateStatus from "../../utils/stateStatus";
// import ImagesCards from "../../utils/images";
import { useNavigate, useParams } from "react-router-dom";
import { requestRefreshToken } from "../../service/api";
import { getProfile } from "./service";

const LoginPage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const [errorMessage, setErroMessage] = useState<string | null>();

    const loginState = useSelector(loginSelector)

    const onFinish = (values) => {
        setErroMessage(null)
        dispatch(loginThunk(values))
    }

    const checkIfExistsSession = async () => {
        if(params.token) {
            dispatch(loginWithTokenThunk({ token: params.token }))
        } else if (localStorage.getItem("access_token")) {
            try {
                await getProfile()
                navigate("/dashboard");
            } catch (err) {
                await requestRefreshToken()
            }
        }
    }

    useEffect(() => {
        checkIfExistsSession()
    }, [])

    

    useEffect(() => {
        if (loginState.status.login === StateStatus.failed) {
            setErroMessage("E-mail ou senha incorretos!")
        }

        if (loginState.status.login === StateStatus.succeeded) {
            dispatch(getProfileThunk());
            dispatch(resetLoginState());
            navigate("/dashboard");
        }

    }, [loginState.status.login])

    useEffect(() => {
        if (loginState.status.loginWithToken === StateStatus.failed) {
            message.error("E-mail ou senha incorretos!")
        }

        if (loginState.status.loginWithToken === StateStatus.succeeded) {
            dispatch(getProfileThunk());
            dispatch(resetLoginState());
            navigate("/dashboard");
        }

    }, [loginState.status.loginWithToken])

    return (
        <>
            <div className="login-container">
                <div className="login-logo">
                </div>

                <div className="login-form">

                    <div className="login-form-logo">
                        <img src={logo} alt="Inquest" />
                    </div>

                    <div className="auth-page-name">
                        ENTRAR
                    </div>

                    <div className="form-login">
                        {loginState.status.login === StateStatus.failed && (
                            <div className="error-message-login">{errorMessage ?? ""}</div>
                        )}

                        <Form onFinish={onFinish} layout="vertical">
                            <Form.Item
                                name="email"
                                label="Email"
                                initialValue=""
                                rules={[
                                    {
                                        required: true,
                                        message: <div className="normal-12">Informe um e-mail válido!</div>,
                                    },
                                    {
                                        type: "email",
                                        message: <div className="normal-12">Informe um e-mail válido!</div>,
                                    }
                                ]}
                            >
                                <Input placeholder="Digite seu e-mail" type={"email"} />
                            </Form.Item>

                            <Form.Item
                                name="password"
                                label="Senha"
                                initialValue=""
                                rules={[
                                    {
                                        required: true,
                                        message: <div className="normal-12">Digite sua senha!</div>,
                                    },
                                ]}
                            >
                                <Input placeholder="Digite seu e-mail" type="password" />
                            </Form.Item>

                            <div className="link-esqueci-minha-senha">
                                <a href="#/esqueci-minha-senha">Esqueceu a senha?</a>
                            </div>

                            <div className="login-form-button-pagina-login">
                                {/* <PlainButton 
                                    loading={loginState.status.login === StateStatus.loading} 
                                    disabled={loginState.status.login === StateStatus.loading} 
                                    color={"primary"}
                                    htmlType="submit">Entrar</PlainButton> */}

                                <div className="login-new-account-pagina-login">
                                    <a href="#/nova-conta/1">Primeira vez aqui? Criar conta</a>
                                </div>
                                
                                <BlueSquareButton isLoading={loginState.status.login === StateStatus.loading}>ACESSAR</BlueSquareButton>
                            </div>

                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
}

const Logout = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const loginState = useSelector(loginSelector)


    useEffect(() => {
        dispatch(logoutThunk())
    }, [])

    useEffect(() => {
        if(loginState.status.logout === StateStatus.succeeded) {
            navigate('/')
        }
    }, [loginState.status.logout])

    return <></>
}

export default LoginPage
export { Logout }