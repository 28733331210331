
import {
  Button,
  Col,
  message,
  Row,
  Skeleton,
  Table,
  Tag,
  Tooltip,
} from "antd";

import "./style.scss";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { HeaderDash } from "../../../../common/components/header";
import React, { useEffect, useState } from "react";
import StateStatus from "../../../../utils/stateStatus";
import { useDispatch, useSelector } from "react-redux";
import {
  addUserToClientThunk,
  getListUsersThunk,
  resetListUsersCase,
  selectListUsersState,
} from "./slice";
import localeValues from "antd/locale/pt_BR";
import { beautifyCpfCnpj } from "../../../../utils/formatters";
import { getProfileThunk, loginSelector } from "../../../login/slice";
import { Link, useParams } from "react-router-dom";
import EditUserModal from "../../components/edit_user_modal";
import Icons from "../../../../common/components/Icons";
import { ColumnType } from "antd/lib/table";
import { UserType } from "./interfaces";

const UsersClient = () => {
  const dispatch = useDispatch();
  const usersListState = useSelector(selectListUsersState);
  const loginState = useSelector(loginSelector);
  const { clientId } = useParams<{clientId: string}>();

  const profile = loginState.data.profile;
  const client = usersListState.data?.client;
  const [user, setUser] = useState<UserType | null>();

  const renderIcon = (status) => {
    switch (status) {
      case true:
        return (
          <Tag color="green">
            <CheckOutlined />
          </Tag>
        );
      default:
        return (
          <Tag color="red">
            <CloseOutlined />
          </Tag>
        );
    }
  };

  const admColumns: ColumnType<UserType>[] = [
      {
        title: "É funcionário Inquest?",
        dataIndex: "is_staff",
        align: "center",
        responsive: ["lg"],
        width: "10%",
        render: (text) => renderIcon(text),
      },
      {
        title: "É super usuário?",
        dataIndex: "is_superuser",
        align: "center",
        responsive: ["lg"],
        width: "10%",
        onFilter: (value, record) => record.is_superuser === value,
        render: (text) => renderIcon(text),
      },
  ]

  const editColumn: ColumnType<UserType>[] = [
    {
      title: "Editar",
      dataIndex: "view",
      align: "center",
      width: "10%",
      render: (_, record) => (
        <Row gutter={[16, 16]} justify="center">
          <Col>
            <Tooltip title="Editar usuário">
              <Link
                to="#"
                onClick={() => {
                  setUser(record);
                }}
              >
                <Icons
                  icon={"edit"}
                  className="icon-view"
                  style={{ fontSize: 20 }}
                />
              </Link>
            </Tooltip>
          </Col>
        </Row>
      ),
    },
  ]

  const columns: ColumnType<UserType>[] = [
    {
      title: "Nome",
      dataIndex: "name",
      width: "10%",
      render: (_, record) => (
        <Row>
          {/* <span style={{fontSize: "14px"}}>{record.case_name}</span> */}
          <Col span={24}>{record.name}</Col>
          <Col span={24} className="info-user">
            <div>
              {record.is_active ? <><Icons icon={"checked-circle"} /> Usuátio ativo</> : <> <Icons icon={"close-circle"} /> Usuário inativo</>}
            </div>
            {profile?.is_staff || profile?.is_superuser ? (<>
              <div>
                {record.is_superuser ? <><Icons icon={"checked-circle"} /> É super usuário</> : <> <Icons icon={"close-circle"} />Não é super usuário</>}
              </div>
              <div>
                {record.is_staff ? <><Icons icon={"checked-circle"} /> É funcionário Inquest</> : <> <Icons icon={"close-circle"} />Não é funcionário Inquest</>}
              </div>
            </>) : null}
            <div>
                {record.is_client_adm ? <><Icons icon={"checked-circle"} /> É admin. do cliente</> : <> <Icons icon={"close-circle"} />Não é admin. do cliente</>}
              </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "E-mail",
      responsive: ["lg"],
      width: "10%",
      dataIndex: "email",
    },
    ...(profile?.is_staff || profile?.is_superuser
      ? admColumns
      : []),
    {
      title: "Pode admin. usuários do cliente?",
      dataIndex: "is_client_adm",
      align: "center",
      responsive: ["lg"],
      width: "10%",
      render: (text) => renderIcon(text),
    },
    {
      title: "Usuário ativo?",
      dataIndex: "is_active",
      filterSearch: true,
      align: "center",
      responsive: ["lg"],
      width: "10%",
      onFilter: (value, record) => record.is_active === value,
      render: (text) => renderIcon(text),
    },
    ...(profile?.is_staff || profile?.is_superuser || profile?.is_client_adm
      ? editColumn
      : []),
    // {
    //     title: 'Ação',
    //     dataIndex: 'action',
    //     align: 'center',
    //     render: (_, record) => (
    //         <Space size="middle">
    //             <Link onClick={() => alert('Edit: ' + record.name)}>Editar</Link>
    //             <Link onClick={() => alert('Remove: ' + record.name)}>Remover</Link>
    //         </Space>
    //     ),
    // },
  ];

  useEffect(() => {
    dispatch(getProfileThunk());
  }, []);

  useEffect(() => {
    if(clientId) {
      dispatch(getListUsersThunk(clientId));
    }
  }, [clientId]);

  //listReports
  useEffect(() => {
    // eslint-disable-next-line default-case
    switch (usersListState.status.listUsers) {
      // case StateStatus.succeeded: {
      //   updateData(usersListState.data.users);
      //   break;
      // }
      case StateStatus.failed: {
        dispatch(resetListUsersCase());
        console.error(usersListState.errMessage);
        break;
      }
    }
  }, [usersListState.status.listUsers]);

  useEffect(() => {
    // eslint-disable-next-line default-case
    if (usersListState.status.addUserToClient === StateStatus.succeeded) {
      dispatch(getListUsersThunk(clientId!));
      message.success(
        user?.email
          ? "Usuário editado com sucesso!"
          : "Usuário cadastrado com sucesso!"
      );
      setUser(null);
    }

    if (usersListState.status.addUserToClient === StateStatus.failed) {
      message.error("Não foi possível adicionar usuário.");
    }
  }, [usersListState.status.addUserToClient]);

  return (
    <>
      <div className="users-client-page">
        <HeaderDash />
        <div className="users-container">
          <div className="info-title-container">
              <h1 className="info-title">Usuários cadastrados</h1>
              <p className="info-text">
                Veja abaixo os usuários cadastrados para o cliente abaixo.
              </p>
          </div>
          {usersListState.status.listUsers === StateStatus.loading ? (
            <div style={{ width: "100%", height: "150px" }}>
              <Skeleton active />
            </div>
          ) : (
            <>
              <Row>
                <Col span={24}>
                  <b>Dados do cliente</b>
                  <br />
                  <br />
                </Col>
                <Col span={24}>
                  <b>CPF/CNPJ:</b> {beautifyCpfCnpj(client?.cpf_cnpj)}
                </Col>
                <Col span={24}>
                  <b>E-mail:</b> {client?.email}
                </Col>
                <Col span={24}>
                  <b>Nome:</b> {client?.name}
                </Col>
                <Col span={24}>
                  <b>Faturamento:</b>{" "}
                  {client?.billing_mode_display?.toUpperCase() ?? "-"}
                </Col>
                <Col span={24}>
                  <br />
                  <br />
                  <b>Usuários associados a este cliente:</b>
                </Col>
              </Row>
              <div className="users-box">
                <Row justify="end">
                  <Col>
                    <Button
                      className={"btn-blue"}
                      onClick={() => setUser({email: "", name: ""})}
                      icon={<Icons icon="add-user" />}
                    >
                      Novo usuário
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col md={24} sm={24}>
                    <Table
                      // rowKey={(record) => record.id}
                      columns={columns}
                      dataSource={usersListState.data.users ?? []}
                      locale={localeValues.Table}
                      onRow={(record, rowIndex) => {
                        return {
                          onClick: (event) => {
                            setUser(record);
                          },
                        };
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </>
          )}
        </div>
      </div>
      {user ? (
        <EditUserModal
          clientName={`${client?.name} - ${client?.cpf_cnpj}`}
          isVisible={true}
          user={user}
          isAdmUser={profile?.is_staff || profile?.is_superuser}
          isClientAdm={profile?.is_client_adm}
          onCancel={() => setUser(null)}
          isLoading={
            usersListState.status.addUserToClient === StateStatus.loading
          }
          onSave={(values) => {
            dispatch(
              addUserToClientThunk({
                clientId: clientId!,
                data: values,
              })
            );
          }}
        />
      ) : null}
    </>
  );
};

export default UsersClient;
