import React, { useEffect, useState } from "react"

import { Col, Form, Modal, Result, Row } from "antd"
// @ts-ignore
import logo from "../../common/images/logo_azul.png";
import Input from "../../common/components/input";
import { BlueSquareButton } from "../../common/components/button";
import { forgotPassword, resetPasswState, selectPasswordState } from "./slice";
import { useDispatch, useSelector } from "react-redux";
import StateStatus from "../../utils/stateStatus";


import './style.scss';
import { ForgotPasswordPayload } from "./interfaces";

const ForgotPasswordPage = () => {
    const dispatch = useDispatch()

    const [form] = Form.useForm()
    const passwordState = useSelector(selectPasswordState)
    const [showModal, updateShowModal] = useState<"error-sent" | "success-sent" | null>(null)
    const [loading, setLoading] = useState(false);

    const onFinish = (values: unknown) => {
        dispatch(forgotPassword(values as ForgotPasswordPayload))
    }

    useEffect(() => {
        switch (passwordState.status.forgotPassword) {
            case StateStatus.loading:
                setLoading(true)
                break;

            case StateStatus.failed:
                updateShowModal("error-sent")
                dispatch(resetPasswState())
                setLoading(false)
                break

            case StateStatus.succeeded:
                updateShowModal("success-sent")
                form.resetFields();
                setLoading(false)
                break

            default:
                break
        }

    }, [passwordState.status.forgotPassword])

    return (
        <>
            {/* <Header /> */}
            <div className="login-forgot-container">
                <div className="register-left">

                </div>
                <div className="register-rigth">
                    <div className="login-forgot-form">

                        <div className="login-forgot-form-logo">
                            <img src={logo} alt="Inquest" />
                        </div>


                        <Row>
                            <Col span={24} align="center">
                                <div className="main-text">
                                    <div className="auth-page-name">ALTERAR SENHA</div>
                                    <h2 className="form-subtitle">Digite seu e-mail abaixo e receber as instruções para alterar sua senha</h2>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={24} align="center">
                                <Form form={form} className="form" onFinish={onFinish}>
                                    <Form.Item
                                        name="email"
                                        initialValue=""
                                        rules={[
                                            {
                                                required: true,
                                                message: <div className="normal-12">Informe um e-mail válido!</div>,
                                            },
                                            {
                                                type: "email",
                                                message: <div className="normal-12">Informe um e-mail válido!</div>,
                                            }
                                        ]}
                                    >
                                        <Input placeholder="Digite seu e-mail" />
                                    </Form.Item>


                                    <div className="login-forgot-form-button">
                                        <BlueSquareButton
                                            isLoading={loading}
                                            disabled={loading}
                                            htmlType="submit"
                                        >SOLICITAR ALTERAÇÃO</BlueSquareButton>
                                        {/* <RoudedButton loading={loading} disabled={loading} className="btn-blue" htmlType="submit">Enviar</RoudedButton> */}
                                    </div>

                                </Form>
                            </Col>
                        </Row>

                        <div className="login-new-account">
                            <a href="#/">Ir para login</a>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                open={showModal === "error-sent"}
                footer={null}
                onCancel={() => updateShowModal(null)}
            >
                <Result
                    status="error"
                    title="Usuário não encontrado!"
                    subTitle={<div>Não foi encontrado usuário com este e-mail, por favor, revise o e-mail digitado ou <a style={{textDecoration: "underline", fontWeight: 600}} href="#/nova-conta/1">clique aqui para criar sua conta.</a></div>}
                    extra={[
                        <BlueSquareButton onClick={() => {
                            updateShowModal(null)
                            window.location.href = '#/nova-conta/1'
                        }}>
                            registrar
                        </BlueSquareButton>,
                    ]}
                />
            </Modal>
            <Modal
                open={showModal === "success-sent"}
                footer={null}
                onCancel={() => updateShowModal(null)}
            >
                <Result
                    status="success"
                    title="Email enviado!"
                    subTitle="Um e-mail com as informações para resetar sua senha foi enviado! Verifique sua caixa de entrada."
                    extra={[
                        <BlueSquareButton onClick={() => {
                            updateShowModal(null)
                        }}>
                            Fechar
                        </BlueSquareButton>,
                    ]}
                />
            </Modal>
        </>
    );
}

export default ForgotPasswordPage