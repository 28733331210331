import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit"
import { getServiceCounters, getServiceResultData, getTarget, getTargetCounters } from "./service"
import StateStatus from "../../../../utils/stateStatus"
import { GetServiceCounterThunkArgs, GetServiceDataThunkArgs, GetTargetResponse, SectionData, ServiceCountersResponse, ServiceDataResponse, ServicesMapType, TargetDataType } from "./interfaces"
import { RootState } from "../../../../app/store";


// Tipos do estado inicial
interface TargetPageState {
    status: {
        getTarget: StateStatus;
        getServiceData: StateStatus;
        getServiceCounters?: StateStatus;
        getTargetCounters?: StateStatus;
    };
    data: {
        target: TargetDataType | undefined | null;
    };
    errMessage: {
        getTarget: string | null;
        getServiceData?: string | null;
        getServiceCounters?: string | null;
    };
}

export const getTargetThunk = createAsyncThunk<GetTargetResponse, string>(
    "target-page/get",
    async (targetId: string) => {
        const response = await getTarget(targetId)
        return response.data
    }
)

export const getServiceDataThunk = createAsyncThunk<ServiceDataResponse, GetServiceDataThunkArgs>(
    "target-page/get-data",
    async ({ service, dossierId, section, page, filterBy, sortBy, subItemsMap }, { getState }) => {
        const { target: state } = getState() as RootState
        const serviceData = state.data.target!.services[service]

        if(serviceData.dossier_id === dossierId && serviceData.sections && !subItemsMap) {
            const sectionData: SectionData = serviceData.sections[section]

            if(sectionData?.data?.pagination?.current_page === page) {
                throw {
                    message: "SECTION_PAGE_ALREADY_REQUESTED",
                    name: "SectionException",
                }
            }
        }
        
        const response = await getServiceResultData({
            service,
            dossier_id: dossierId,
            section,
            page,
            per_page: 15,
            filters: filterBy ? JSON.stringify(filterBy) : null,
            sort_by: sortBy ? JSON.stringify(sortBy) : null,
        })
        return response.data
    }
)

export const getTargetCountersThunk = createAsyncThunk<GetTargetResponse, string>(
    "target-page/get-target-counters",
    async (targetId: string) => {
        const response = await getTargetCounters(targetId)
        return response.data
    }
)

export const getServiceCounterThunk = createAsyncThunk<ServiceCountersResponse, GetServiceCounterThunkArgs>(
    "target-page/get-counters",
    async ({ service, dossierId }) => {
        const response = await getServiceCounters({
            service,
            dossier_id: dossierId,
        })
        return response.data
    }
)



const initialState: TargetPageState = {
    status: {
        getTarget: StateStatus.idle,
        getServiceData: StateStatus.idle,
        getServiceCounters: StateStatus.idle,
        getTargetCounters: StateStatus.idle,
    },
    data: {
        target: null,
    },
    errMessage: {
        getTarget: null,
    }
}

export const targetSlice = createSlice({
    name: "target-page-slice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // getTargetThunk
            .addCase(getTargetThunk.pending, (state) => {
                state.status.getTarget = StateStatus.loading
            })
            .addCase(getTargetThunk.fulfilled, (state, action) => {
                const services: ServicesMapType = {}

                action.payload.services.forEach(item => {
                    services[item.service!] = item
                })

                state.data.target = action.payload as TargetDataType
                state.data.target.services = services // TODO: testar alternancia de List para Map do services
                state.status.getTarget = StateStatus.succeeded
            })
            .addCase(getTargetThunk.rejected, (state, action) => {
                state.status.getTarget = StateStatus.failed
                state.errMessage.getTarget = action.error.message || null
            })

            // getServiceDataThunk
            .addCase(getServiceDataThunk.pending, (state, action) => {
                state.status.getServiceData = StateStatus.loading

                const { section, page, subItemsMap } = action.meta.arg
                type ServiceMapKey = keyof ServicesMapType;
                const service: ServiceMapKey = action.meta.arg.service as ServiceMapKey

                
                if (!state.data.target!.services[service]?.sections || !state.data.target!.services[service].id) {
                    state.data.target!.services[service] = {sections: {}, counters: {}, ...state.data.target!.services[service]}
                }

                if(state.data.target!.services[service].sections[section]?.loadingPage === page && !subItemsMap) {
                    throw {
                        message: "SECTION_PAGE_ALREADY_REQUESTED",
                        name: "SectionException",
                    }
                }
                
                state.data.target!.services[service].sections[section] = { 
                    status: StateStatus.loading,
                    loadingPage: page,
                }
            })
            .addCase(getServiceDataThunk.fulfilled, (state, action) => {
                const { service, section, subItemsMap } = action.meta.arg

                if(!subItemsMap) {
                    state.data.target!.services[service].sections[section].data = action.payload
                } else {
                    const item = state.data.target!.services[service].sections[subItemsMap.sectionKey].data.items[subItemsMap.itemIndex]
                    state.data.target!.services[service].sections[subItemsMap.sectionKey].data.items[subItemsMap.itemIndex] = {...item, [subItemsMap.itemKey]: {status: StateStatus.succeeded, data: action.payload}}
                }

                state.data.target!.services[service].sections[section].status = StateStatus.succeeded
                state.data.target!.services[service].sections[section].loadingPage = null

                state.status.getServiceData = StateStatus.succeeded
            })
            .addCase(getServiceDataThunk.rejected, (state, action) => {
                const { service, section } = action.meta.arg

                state.data.target!.services[service].sections[section].status = StateStatus.failed
                state.data.target!.services[service].sections[section].loadingPage = null
                state.status.getServiceData = StateStatus.failed
                state.errMessage.getServiceData = action.error.message || null
            })


            // getTargetCountersThunk
            .addCase(getTargetCountersThunk.pending, (state, action) => {
                state.status.getTargetCounters = StateStatus.loading

                Object.keys(state.data.target?.services ?? {})?.forEach(service => {
                    if (!state.data.target!.services[service]?.id) {
                        state.data.target!.services[service] = {}
                    }

                    if (!state.data.target!.services[service].counters) {
                        state.data.target!.services[service].counters = { status: StateStatus.loading }
                    }
                })
            })
            .addCase(getTargetCountersThunk.fulfilled, (state, action) => {

                action.payload.services.forEach(service => {
                    if(state.data.target!.services[service.service!]?.counters) {
                        state.data.target!.services[service.service!].counters = { 
                            ...action.payload.services.filter(x => x.service === service.service)[0], 
                            status: StateStatus.succeeded,
                        }
                    }
                })
                state.status.getTargetCounters = StateStatus.succeeded
            })
            .addCase(getTargetCountersThunk.rejected, (state, action) => {

                Object.keys(state.data.target?.services ?? {})?.forEach(service => {
                    state.data.target!.services[service].counters = { status: StateStatus.failed }
                })

                state.status.getTargetCounters = StateStatus.failed
                state.errMessage.getServiceCounters = action.error.message || null
            })

            // getServiceCounterThunk
            .addCase(getServiceCounterThunk.pending, (state, action) => {
                state.status.getServiceCounters = StateStatus.loading

                const { service } = action.meta.arg

                if (!state.data.target!.services[service]?.id) {
                    state.data.target!.services[service] = {}
                }

                if (!state.data.target!.services[service].counters) {
                    state.data.target!.services[service].counters = { status: StateStatus.loading }
                }
            })
            .addCase(getServiceCounterThunk.fulfilled, (state, action) => {
                const { service } = action.meta.arg

                if(state.data.target!.services[service]?.counters) {
                    state.data.target!.services[service].counters = { ...action.payload, status: StateStatus.succeeded }
                }
                state.status.getServiceCounters = StateStatus.succeeded
            })
            .addCase(getServiceCounterThunk.rejected, (state, action) => {
                const { service } = action.meta.arg

                state.data.target!.services[service].counters = { status: StateStatus.failed }
                state.status.getServiceCounters = StateStatus.failed
                state.errMessage.getServiceCounters = action.error.message || null
            })
    }
})

export const selectTargetPageState = (state: { target: TargetPageState }) => state.target
